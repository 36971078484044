import { observable } from "mobx";
import { observer, inject } from "mobx-react"
import * as React from "react";
import { graphql } from "gatsby";
import { faPencilAlt, faTrashAlt } from "@fortawesome/pro-solid-svg-icons"

import IconButton from "../../components/iconButton";
import Button from "../../components/button";
import Input from "../../components/input";
import Loader from "../../components/loader";
import ShopHeader from "../../components/shopHeader";
import { OrderItem, OrderStore, UIStore, ShopStore, VisitorStore } from "stores";
import { getOrderTotals } from "../../utils/totals";
import RadioList, { RadioListItem } from "../../components/radioList";
import SEO from "../../components/seo";
import { PAGE_VIEW_EVENT, ADD_ANOTHER_IMAGE_EVENT, NAVIGATE_EVENT, EDIT_IMAGE_FROM_CART_EVENT, INPUT_COUPON_EVENT, INPUT_GIFTCARD_EVENT, REMOVE_IMAGE_FROM_CART_EVENT, SELECT_UPSELL_EVENT } from "../../utils/constants";

@inject("orderStore", "shopStore", "uiStore", "visitorStore")
@observer
export default class CartPage extends React.Component<any> {
  @observable couponOpen: boolean;
  @observable giftcardOpen: boolean;
  @observable couponCode: string;
  @observable giftCode: string;
  constructor(props: any) {
    super(props);
    this.couponCode = "";
    this.giftCode = "";
  }
  componentWillMount() {
    const orderStore: OrderStore = this.props.orderStore;
    const shopStore: ShopStore = this.props.shopStore;

    if (orderStore.current.upsells.length == 0) {
      orderStore.current.upsells[0] = shopStore.current.upsells[0];
    }
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "shop-cart"
    });
  }
  render() {
    const texts = this.props.data.squidexTexts;
    return (
      <>
        <SEO subTitle={texts.pageShopCart[process.env.GATSBY_LANGUAGE]} />
        {this.maybeRenderLoader()}
        <ShopHeader location={this.props.location} />
        <div className="mb0 mb3-ns overflow-auto">
          <div className="cf pa0-ns mt2 center">
            <Button size={4} className="fl" label={texts.btnAddProduct[process.env.GATSBY_LANGUAGE]} color="theme" inversed={true} onClick={this.handleAddItem} />
            <Button size={4} className="fr" label={texts.btnNextStep[process.env.GATSBY_LANGUAGE]} color="theme" onClick={this.handleNextStep} />
          </div>
        </div >
        {this.renderItems()}
        <div className="cf" />
        <h2 className="f3 mt3 mb3">{texts.txtUpsell[process.env.GATSBY_LANGUAGE]}</h2>
        {this.renderUpsells()}
        {this.renderCouponInput()}
        {this.renderGiftcardInput()}
        {this.renderTotals()}
        <div className="cf w-100 tc mt2 mb2">
          <Button className="w-100 w-auto-ns tc fr" size={4} color="theme" label={texts.btnNextStep[process.env.GATSBY_LANGUAGE]} onClick={this.handleNextStep} />
        </div >
      </>
    )
  }
  maybeRenderLoader = () => {
    const store: OrderStore = this.props.orderStore;
    if (store.fetching) {
      return (<Loader />);
    }
    return null;
  }
  renderUpsells = () => {
    const shopStore: ShopStore = this.props.shopStore;
    const orderStore: OrderStore = this.props.orderStore;
    const upsells = shopStore.current.upsells;
    const upsellItems = upsells.map(u => ({ value: u, label: u.name }));
    const upsell = orderStore.current.upsells[0];
    const upsellprice = orderStore.current.getUpsellPrice(upsell);
    const defaultValue = { value: upsell, label: upsell ? upsell.name : "" };
    return (
      <section id="upsells" className="w-50-ns">
        <RadioList items={upsellItems} className="dib w-80" onChange={this.handleUpsellChange} defaultValue={defaultValue} />
        <div className="dib w-20 tr">&euro; {upsellprice.toFixed(2)}</div>
      </section>
    )
  }
  renderTotals = () => {
    const texts = this.props.data.squidexTexts;
    const { subTotal, discount, deliverCost, giftAmount, total } = getOrderTotals(
      this.props.shopStore,
      this.props.orderStore
    );
    return (
      <section id="totals" className="fl w-100 w-third-ns mt2">
        <div className="fl w-two-thirds tl tr-ns">
          <p className="b mb2">{texts.txtSubtotal[process.env.GATSBY_LANGUAGE]}</p>
          {discount > 0 ? <p className="b mb2">{texts.txtDiscount[process.env.GATSBY_LANGUAGE]}</p> : undefined}
          {giftAmount > 0 ? <p className="b mb2">{texts.txtGiftcard[process.env.GATSBY_LANGUAGE]}</p> : undefined}
          <p className="b mb2">{texts.txtDeliverCosts[process.env.GATSBY_LANGUAGE]}</p>
          <div className="mt1 mb1 w-100 bb b--black-20"></div>
          <p className="b">{texts.txtTotal[process.env.GATSBY_LANGUAGE]}</p>
        </div>
        <div className="fl w-third tr">
          <p className="mb2">&euro; {subTotal.toFixed(2)}</p>
          {discount > 0 ? <p className="mb2">&euro; -{discount.toFixed(2)}</p> : undefined}
          {giftAmount > 0 ? <p className="mb2">&euro; -{giftAmount.toFixed(2)}</p> : undefined}
          <p className="mb2">&euro; {deliverCost.toFixed(2)}</p>
          <div className="mt1 mb1 w-100 bb b--black-20"></div>
          <p>&euro; {total.toFixed(2)}</p>
        </div>
      </section>
    )
  }
  renderCouponInput = () => {
    const texts = this.props.data.squidexTexts;
    if (!this.couponOpen) {
      return (
        <div className="fl tc w-100 w-third-ns mt2 pr2-ns">
          <div className="blue trigger tl mt2" onClick={this.handleCouponOpen}>{texts.txtHaveCoupon[process.env.GATSBY_LANGUAGE]}</div>
        </div>
      )
    }
    return (
      <div className="fl tc w-100 w-third-ns mt2 pr2-ns">
        <Input label={texts.txtCouponCode[process.env.GATSBY_LANGUAGE]} onChange={this.handleCouponcodeChange} />
        <Button className="w-100 mt2" label={texts.btnApply[process.env.GATSBY_LANGUAGE]} color="theme" inversed={true} onClick={this.handleApplyCoupon} />
      </div>
    )
  }
  renderGiftcardInput = () => {
    const texts = this.props.data.squidexTexts;
    if (!this.giftcardOpen) {
      return (
        <div className="fl tc w-100 w-third-ns mt2 pr2-ns">
          <div className="blue trigger tl mt2" onClick={this.handleGiftcardOpen}>{texts.txtHaveGiftcard[process.env.GATSBY_LANGUAGE]}</div>
        </div>
      )
    }
    return (
      <div className="fl tc w-100 w-third-ns mt2 pr2-ns">
        <Input label={texts.txtGiftcard[process.env.GATSBY_LANGUAGE]} onChange={this.handleGiftcodeChange} />
        <Button className="w-100 mt2" label={texts.btnApply[process.env.GATSBY_LANGUAGE]} color="theme" inversed={true} onClick={this.handleApplyGiftcard} />
      </div>
    )
  }
  renderItems = () => {
    const store: OrderStore = this.props.orderStore;
    const texts = this.props.data.squidexTexts;
    return (
      <section id="cart">
        <div className="dt mt2 w-100">
          <div className="dt-row w-100">
            <div className="pv2 dtc b w3 bb b--black-10">{/*texts.txtImage[process.env.GATSBY_LANGUAGE]*/}</div>
            <div className="pv2 dtc b pl3 bb b--black-10">{texts.txtProduct[process.env.GATSBY_LANGUAGE]}</div>
            <div className="pv2 dtc b pl3 w3 bb b--black-10">{texts.txtAmount[process.env.GATSBY_LANGUAGE]}</div>

            <div className="pv2 dn dtc-ns b pl3 w2 bb b--black-10"></div>
            <div className="pv2 dn dtc-ns w4 b tr pl3 bb b--black-10">{texts.txtPricePerPiece[process.env.GATSBY_LANGUAGE]}</div>
            <div className="pv2 dn dtc-ns w4 b tr pl3 bb b--black-10">{texts.txtSubtotal[process.env.GATSBY_LANGUAGE]}</div>
          </div>
        </div>
        {
          store.current.items.map(this.renderItem)
        }
      </section>
    )
  }
  renderItem = (i: OrderItem, index: number) => {
    const texts = this.props.data.squidexTexts;
    return (
      <React.Fragment key={`item-${index}`}>
        <div className="dt mt2 w-100">
          <div className="dt-row w-100">
            <div className="dtc v-mid w3">
              <img className="br3 ba b--black-10 h3 w3" src={i.thumb} />
            </div>
            <div className="dtc pl3">
              <p className="b">{i.material.name}</p>
              <p>{texts.txtFormat[process.env.GATSBY_LANGUAGE]}: {i.absWidth}x{i.absHeight}</p>
              {
                i.options.map((o, index) => (
                  <p key={`item-${index}-opt-${o.id}`}>- {o.name}</p>
                ))
              }
            </div>
            <div className="dtc v-mid w3 pl3">
              <div className="dib w-100 tc relative">
                <Input initialValue={i.amount.toString()} onChange={this.handleAmountChange(i)} />
              </div>
            </div>
            <div className="dn dtc-ns v-mid w2 pl3">
              <IconButton icon={faPencilAlt} color="gray" onClick={this.handleEditItem(i)} />
              <IconButton icon={faTrashAlt} color="red" onClick={this.handleDeleteItem(i)} />
            </div>
            <div className="dn dtc-ns v-mid w4 tr pl3">
              &euro; {i.price.toFixed(2)}
            </div>
            <div className="dn dtc-ns v-mid tr w4 pl3">
              &euro; {(i.price * i.amount).toFixed(2)}
            </div>
          </div>
        </div >
        <div className="db dn-ns mt2 mb3 w-100 overflow-auto">
          <Button className="fl" size={4} label={texts.txtEdit[process.env.GATSBY_LANGUAGE]} color="theme" inversed={true} onClick={this.handleEditItem(i)} />
          <Button className="fr" size={4} label={texts.txtDelete[process.env.GATSBY_LANGUAGE]} color="red" inversed={true} onClick={this.handleDeleteItem(i)} />
        </div >
      </React.Fragment>
    )
  }
  handleAmountChange = (item: OrderItem) => (v: string) => {
    const value = parseInt(v);
    if (!isNaN(value)) {
      item.amount = value;
    }
  }
  handleGiftcardOpen = () => { this.giftcardOpen = true; }
  handleCouponOpen = () => { this.couponOpen = true; }
  handleAddItem = () => {
    const store: OrderStore = this.props.orderStore;
    store.clearItem();
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(ADD_ANOTHER_IMAGE_EVENT);
    visitorStore.logEvent(NAVIGATE_EVENT, {
      location: this.props.location,
      href: this.props.data.squidexConfig.pageShopSlug.iv.toLowerCase()
    });

    this.props.navigate(this.props.data.squidexConfig.pageShopSlug.iv.toLowerCase());
  }
  handleNextStep = () => {
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(NAVIGATE_EVENT, {
      location: this.props.location,
      href: this.props.data.squidexConfig.pageCustomerInfoSlug.iv.toLowerCase()
    });

    this.props.navigate(this.props.data.squidexConfig.pageCustomerInfoSlug.iv.toLowerCase());
  }
  handleCouponcodeChange = (code: string) => {
    this.couponCode = code;
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(INPUT_COUPON_EVENT, code);
  }
  handleGiftcodeChange = (code: string) => {
    this.giftCode = code;
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(INPUT_GIFTCARD_EVENT, code);
  }
  handleApplyCoupon = () => {
    const store: OrderStore = this.props.orderStore;
    const uiStore: UIStore = this.props.uiStore;
    const texts = this.props.data.squidexTexts;
    store.checkCoupon(this.couponCode).then((coupon) => {
      if (!coupon) {
        uiStore.showModal(texts.txtGenericErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtGenericErrorBody[process.env.GATSBY_LANGUAGE]);
      }
      if (!coupon.isValid) {
        uiStore.showModal(texts.txtCouponCodeInvalidErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtCouponCodeInvalidErrorBody[process.env.GATSBY_LANGUAGE]);
      }
    });
  }
  handleApplyGiftcard = () => {
    const store: OrderStore = this.props.orderStore;
    const uiStore: UIStore = this.props.uiStore;
    const texts = this.props.data.squidexTexts;
    store.checkGiftcard(this.giftCode).then((giftcard) => {
      if (!giftcard) {
        uiStore.showModal(texts.txtGenericErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtGenericErrorBody[process.env.GATSBY_LANGUAGE]);
      }
      if (!giftcard.isValid) {
        uiStore.showModal(texts.txtGiftcardCodeInvalidErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtGiftcardCodeInvalidErrorBody[process.env.GATSBY_LANGUAGE]);
      }
    });
  }
  handleEditItem = (i: OrderItem) => {
    return () => {
      const store: OrderStore = this.props.orderStore;
      store.currentItem = i;
      const visitorStore: VisitorStore = this.props.visitorStore;
      visitorStore.logEvent(EDIT_IMAGE_FROM_CART_EVENT, i);
      visitorStore.logEvent(NAVIGATE_EVENT, {
        location: this.props.location,
        href: this.props.data.squidexConfig.pageShopSlug.iv.toLowerCase()
      });

      this.props.navigate(this.props.data.squidexConfig.pageShopSlug.iv.toLowerCase());
    }
  }
  handleDeleteItem = (i: OrderItem) => {
    return () => {
      const store: OrderStore = this.props.orderStore;
      const visitorStore: VisitorStore = this.props.visitorStore;
      visitorStore.logEvent(REMOVE_IMAGE_FROM_CART_EVENT, i);
      store.deleteItem(i);
    }
  }
  handleUpsellChange = (i: RadioListItem) => {
    const store: OrderStore = this.props.orderStore;
    store.current.upsells[0] = i.value;
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(SELECT_UPSELL_EVENT, i.value);
  }
}

export const pageQuery = graphql`
{
  squidexConfig(identity: { eq: "config"}) {
    pageCustomerInfoSlug {
      iv
    }
    pageShopSlug {
      iv
    }
  }
  squidexTexts(identity:{eq: "squidextexts"}) {
	pageShopCart {
    de
    nl
    en
  }
  btnAddProduct {
    de
    nl
    en
  }
  btnNextStep {
    de
    nl
    en
  }
  txtImage {
    de
    nl
    en
  }
  txtProduct {
    de
    nl
    en
  }
  txtAmount {
    de
    nl
    en
  }
  txtFormat{
    de
    nl
    en
  }
  txtPricePerPiece {
    de
    nl
    en
  }
  txtSubtotal {
    de
    nl
    en
  }
  txtEdit {
    de
    nl
    en
  }
  btnApply {
    de
    nl
    en
  }
  txtDelete {
    de
    nl
    en
  }
  txtDiscount {
    de
    nl
    en
  }
  txtUpsell {
    de
    nl
    en
  }
  txtDeliverCosts {
    de
    nl
    en
  }
  txtTotal {
    de
    nl
    en
  }
  txtGiftcard {
    de
    nl
    en
  }
  txtHaveGiftcard {
    de
    nl
    en
  }
  txtHaveCoupon {
    de
    nl
    en
  }
  txtCouponCode {
    de
    nl
    en
  }
  txtCouponCodeInvalidErrorHeader {
    de
    nl
    en
  }
  txtCouponCodeInvalidErrorBody {
    de
    nl
    en
  }
  txtGiftcardCodeInvalidErrorBody {
    de
    nl
    en
  }
  txtGiftcardCodeInvalidErrorHeader {
    de
    nl
    en
  }
  txtGenericErrorHeader {
    de
    nl
    en
  }
  txtGenericErrorBody {
    de
    nl
    en
  }
}}
`

